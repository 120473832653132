/* Begin header style(s) */

.header-horizontal {
  width: 100%;
  position: absolute;
  @include padding(25 0);
  z-index: 120 !important;
  background-color: transparent;
  @extend .easing;

  @include responsive(sm) {
    @include padding(20 0);

    @include respond-to(md) {
      @include padding(20 0);
    }
  }

  > .container-fluid {
    width: 100%;
    @include padding-left(50);
    @include padding-right(50);

    @media (max-width: 991px) {
      @include padding-left(20);
      @include padding-right(20);
    }
  }

  .header-logo {
    width: 250px;
    color: $black;
    @extend .u-flexed;
    @extend .u-align-center;
    text-align: center;

    .logo-animated {
      @include size(45px);
      @extend .vc;
      @include margin-right(8);

      @media (max-width: 767px) {
        @include size(35px);
      }
    }

    .image-fluid {
      width: 100%;
      max-width: 100%;
    }

    @include responsive(sm) {
      width: 200px;
      display: block;

      @include respond-to(md) {
        width: 240px;

        @include respond-to(lg) {
          width: 250px;
        }
      }
    }
  }

  &-main {
    @include flexbox();
    position: relative;
    @include align-items(flex-start);
    @include justify-content(center);
    @include justify-content(space-between);
    @include flex-direction(column, vertical);

    @include responsive(sm) {
      height: auto;
      @include align-items(center);
      @include flex-direction(row, horizontal);

      @include responsive(md) {
        height: auto;
      }
    }
  }
  @media (max-width: 991px) {
    z-index: 120;
    position: absolute;
    border-bottom: 0 none;
  }

  &.is-sticky {
    top: 0;
    left: 0;
    right: 0;
    z-index: 12;
    width: 100%;
    position: fixed;
    @include padding(20 0);
    background-color: rgba($footer, 0.65);
    @include box-shadow(0 10px 25px 0 rgba($black, 0.5));
    @extend .backdrop-md;

    .header-logo {
      color: $white;
    }
  }

  .header-social {
    margin: 0;
    padding: 0;
    padding-left: 30px;

    &-lists {
      margin: 0;
      padding: 0;
      list-style: none;
      @include flexbox();
      @include align-items(center);

      a {
        @include flexbox;
        @include size(36px);
        @include padding(6);
        @include align-items(center);
        @include justify-content(center);
        @extend .easing;

        &:hover {
          @include opacity(0.75);
        }
      }
    }
    &-item {
      margin: 0;
      padding: 0;
      margin-left: 6px;
    }

    @media (max-width: 991px) {
      top: -8px;
      right: 56px;
      position: relative;
    }
  }
}

.navbar {
  display: none !important;
}

@media (max-width: 994px) {
  .navbar-toggler-icon {
    background-image: none !important;
  }
  .navbar {
    width: 100%;
    position: relative;
    display: block !important;

    .collapse:not(.show) {
      display: block !important;
    }

    .container-fluid {
      width: 100%;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .navbar-nav {
      margin-top: 0;
      border-radius: 8px;
      background-color: $footer;

      .nav-link {
        font-size: 1.25rem;
        cursor: pointer;
        text-align: left;
        height: auto !important;
        color: $white !important;
        border: 0 none !important;
        font-weight: $weightMedium;
        padding: 1rem 1.5rem !important;

        &:hover,
        &:focus,
        &:active,
        &.is-active {
          color: $hoverColor !important;
        }

        &::before {
          display: none;
        }
      }
      .nav-item {
        margin-bottom: 0;
      }
    }

    &-toggler {
      top: 0;
      right: 0;
      color: $white;
      position: absolute;
      border: 0 none !important;
      box-shadow: none !important;
      background-color: transparent !important;
      padding: 0rem !important;
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%2837, 207, 123, 1%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e") !important;
      background-size: 30px auto !important;
      background-repeat: no-repeat;
      background-position: center;
    }

    .menu-overlay {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100vh;
      content: " ";
      z-index: 9999;
      // display: none;
      position: fixed;
      background: rgba($black, 0.65);
      @include transition(all ease 0.3s);
    }

    .responsive-navigation {
      $responsiveStandaloneSelf: &;

      top: 0;
      right: 0;
      width: 280px;
      overflow: hidden;
      text-align: left;
      z-index: 99999999;
      padding: 3.75rem 0rem 1.875rem;
      position: fixed !important;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      height: 100% !important;
      min-height: 100lvh !important;
      background-color: $footer;
      @include transform(translateZ(0));
      @include transform(translateX(100%));
      @include transition(all 0.3s ease);

      .close-menu {
        z-index: 999;
        top: 1.25rem; /* 20px */
        left: 1.25rem; /* 20px */
        color: $white;
        cursor: pointer;
        position: absolute;
        @include size(25px);
        @include font-size(25);
        line-height: 1.5em;
        @include padding(0);
        @include opacity(0);
        @include transform(scale(0));
        @include transition(all 0.6s ease);
        @include transition-duration(0.6s, 0.5s, 0.5s);
        @include transition-property(color, opacity, transform);
        @include transition-timing-function(cubic-bezier(0.4, 0, 0.2, 1));
        @include flexbox;
        @include align-items(center);
        @include justify-content(center);
      }

      [class^="icon-close"] {
        cursor: pointer;
        margin-top: 0px;
        line-height: 20px;
        text-align: center;
        display: inline-block;
        vertical-align: middle;
        @include size(20px, 20px);
        @include border-radius(50%);
        @include transition(all 0.4s ease-in-out);

        &:before,
        &:after {
          top: 50%;
          left: -15%;
          right: -15%;
          content: " ";
          height: 1.5px;
          position: absolute;
          background-color: $primary;
          @include transition(all 0.2s ease-in-out);
        }

        &:before {
          @include transform(rotate(45deg));
        }
        &:after {
          @include transform(rotate(-45deg));
        }

        &:hover {
          &:before,
          &:after {
            background-color: $hoverColor;
          }
        }
      }

      &.show {
        visibility: visible;
        @include transform(translateX(0));

        .close-menu {
          @include opacity(1);
          @include transform(scale(1));
        }
      }
    }
  }
  .navigation-active {
    .menu-overlay {
      display: block;
      @include transition(all ease 0.3s);
    }
  }

  .mobile-toggler {
    right: 0;
    top: -25px;
    position: absolute !important;
  }

  .is-sticky {
    .navbar {
      &-toggler {
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%2837, 207, 123, 1%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e") !important;
      }
    }
  }
}

/* End header style(s) */
