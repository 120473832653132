.rowflex {
    @extend .u-flexed;
    margin-left:-10px;
    margin-right:-10px;
    @include flex-wrap(wrap);
}
[class*="c-item-"] {
    width: 100%;
    min-height: 1px;
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
}
.c-item-full {
    max-width:100%;
    @include flex(0 0 100%);
}
.c-item-half {
    max-width:50%;
    @include flex(0 0 50%);
}
.c-item-oneThird {
    max-width:33.33333%;
    @include flex(0 0 33.33333%);
}
.c-item-quarter {
    max-width:25%;
    @include flex(0 0 25%);
}
.c-item-oneFifth {
    max-width:20%;
    @include flex(0 0 20%);
}

@media (min-width: 576px) {
    .c-item-full-sm-auto {
        width:auto;
        max-width:none;
        @include flex(0 0 auto);
    }
    .c-item-full-sm {
        max-width:100%;
        @include flex(0 0 100%);
    }
    .c-item-half-sm {
        max-width:50%;
        @include flex(0 0 50%);
    }
    .c-item-oneThird-sm {
        max-width:33.33333%;
        @include flex(0 0 33.33333%);
    }
    .c-item-quarter-sm {
        max-width:25%;
        @include flex(0 0 25%);
    }
    .c-item-oneFifth-sm {
        max-width:20%;
        @include flex(0 0 20%);
    }
}

@media (min-width: 768px) {
    .c-item-full-md-auto {
        width:auto;
        max-width:none;
        @include flex(0 0 auto);
    }
    .c-item-full-md {
        max-width:100%;
        @include flex(0 0 100%);
    }
    .c-item-half-md {
        max-width:50%;
        @include flex(0 0 50%);
    }
    .c-item-oneThird-md {
        max-width:33.33333%;
        @include flex(0 0 33.33333%);
    }
    .c-item-quarter-md {
        max-width:25%;
        @include flex(0 0 25%);
    }
    .c-item-oneFifth-md {
        max-width:20%;
        @include flex(0 0 20%);
    }
}

@media (min-width: 992px) {
    .c-item-full-lg-auto {
        width:auto;
        max-width:none;
        @include flex(0 0 auto);
    }
    .c-item-full-lg {
        max-width:100%;
        @include flex(0 0 100%);
    }
    .c-item-half-lg {
        max-width:50%;
        @include flex(0 0 50%);
    }
    .c-item-oneThird-lg {
        max-width:33.33333%;
        @include flex(0 0 33.33333%);
    }
    .c-item-quarter-lg {
        max-width:25%;
        @include flex(0 0 25%);
    }
    .c-item-oneFifth-lg {
        max-width:20%;
        @include flex(0 0 20%);
    }
}

@media (min-width: 1200px) {
    .c-item-full-xl-auto {
        width:auto;
        max-width:none;
        @include flex(0 0 auto);
    }
    .c-item-full-xl {
        max-width:100%;
        @include flex(0 0 100%);
    }
    .c-item-half-xl {
        max-width:50%;
        @include flex(0 0 50%);
    }
    .c-item-oneThird-xl {
        max-width:33.33333%;
        @include flex(0 0 33.33333%);
    }
    .c-item-quarter-xl {
        max-width:25%;
        @include flex(0 0 25%);
    }
    .c-item-oneFifth-xl {
        max-width:20%;
        @include flex(0 0 20%);
    }
}
