/* Begin Navigation style(s) */

.navigation {
  $navigationSelf: &;

  height: 36px;
  text-align: center;
  position: relative;
  @include margin-bottom(0);
  @include align-items(center);

  @media (max-width: 994px) {
    display: none;
  }

  @include responsive(sm) {
    height: 36px;
    @include flexbox();

    @include respond-to(md) {
      @include flexbox();
    }
  }

  &-list {
    @include flexbox;
    text-align: center;
    @include margin(0);
    @include padding(0);
    @include align-items(center);

    li {
      position: relative;
      text-align: center;
      display: inline-block;
      @include margin(0 0 0 5);
      @include align-items(center);

      a {
        height: 36px;
        color: $white;
        cursor: pointer;
        @include flexbox;
        @include font-size(18);
        line-height: 1.5;
        @include padding(6 18);
        font-weight: $weightMedium;
        @include align-items(center);
        text-decoration: none !important;
        @extend .easing;

        &:hover,
        &:focus {
          color: $hoverColor;
        }

        &.is-active {
          color: $hoverColor;
        }

        &.has-child {
          &:after {
            top: 0;
            color: $white;
            line-height: 1;
            font-size: 14px;
            content: "\f107";
            text-shadow: none;
            position: relative;
            text-transform: none;
            font-variant: normal;
            display: inline-block;
            @extend .if-solid;
            @include margin-left(6);
            -webkit-font-smoothing: antialiased;
          }

          &.active {
            &:after {
              color: $hoverColor;
            }
          }
        }
      }

      &.active {
        a {
          color: $hoverColor;
        }
      }

      &:hover a {
        color: $hoverColor;

        &.has-child {
          &:after {
            color: $hoverColor;
          }
        }
      }

      .sub-menu {
        left: 0px;
        top: 100px;
        z-index: 19;
        margin: 0px;
        width: 190px;
        padding: 0px;
        text-align: left;
        visibility: hidden;
        position: absolute;
        @include opacity(0);
        border: 1px solid $border;
        @include border-radius(0px 0px 8px 8px);
        background: $white none repeat scroll 0 0;
        @include transition(all 0.3s linear 0.15s);
        @include box-shadow(0 6px 10px 0 rgba($black, 0.25) !important);

        &.right {
          left: auto;
          right: -14px;
        }

        > li {
          margin: 0;
          height: auto;
          display: block;
          padding: 0px 0px;
          text-align: left;
          background-color: $transparent;
          border-bottom: 1px solid $border;

          &:last-child {
            border-bottom: 0px none;
            @include border-radius(0px 0px 8px 8px);
          }

          a {
            color: $white;
            display: block;
            text-align: left;
            position: relative;
            @include padding(15);
            @include font-size(18);
            font-family: $tertiaryFont;
            /*text-transform: capitalize;*/
            @include transition(all 0.3s ease);
            background: $white none repeat scroll 0 0;

            &:hover,
            &.active {
              color: $white;
              border: 0px none !important;
              background: $blue none repeat scroll 0 0;
            }
          }

          &:hover .sub-menu {
            top: 0;
          }
          &:last-child {
            a {
              @include border-radius(0px 0px 8px 8px);
            }
          }
        }

        .sub-menu {
          top: 100px;
          left: 100%;
        }
      }

      &:hover > .sub-menu {
        top: 65px;
        @include opacity(1);
        visibility: visible;
      }
    }
  }

  @include responsive(md) {
    .navigation {
      display: block;

      &--list {
        width: 100%;
        text-align: center;
        @include inline-flex;
      }
    }
  }

  @include responsive(md) {
    #{$navigationSelf}--list {
      li {
        @include margin(0 2);

        a {
          @include font-size(18);
          line-height: 1.5;
          @include padding(10 14);
        }
      }
    }

    @include respond-to(lg) {
      #{$navigationSelf}--list {
        li {
          @include margin(0 2);

          a {
            @include font-size(18);
            line-height: 1.5;
            @include padding(10 15);
          }
        }
      }
    }
  }
}

@media (min-width: 991x) {
  .page-menu .sticky-hamMenu.desktop {
    display: block;
  }
}

body.noScroll {
  overflow: hidden !important;
}

@media (max-width: 567px) {
  .hidden-xs-device {
    display: none;
  }
}

/* End header style(s) */
