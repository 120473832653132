@charset "utf-8";
/* CSS Document */

/* Basic Includes
  ================================ */
@import "core/reset";
@import "core/variables";
@import "core/mixins";
@import "core/typography";

/* Begin Basic Coding
  ================================ */

html,
body {
  @include size($viewportSize);
  min-height: 100% !important;
}

body {
  margin: 0;
  padding: 0;
  height: auto;
  color: $black;
  font-size: 18px;
  overflow-x: hidden;
  font-family: $primaryFont !important;
  font-weight: $weightRegular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $white none repeat scroll 0 0;

  @media (max-width: 991px) {
    overflow-x: hidden !important;
  }
}

.dark-theme {
  color: $white;
  background: $primary none repeat scroll 0 0;
}

ul li,
ol li {
  font-weight: $weightRegular;
}

/* End Basic Coding
   ================================ */

body {
  @include flexbox;
  min-height: 100vh;
  @include flex-direction(column, vertical);
}
body {
  margin: 0;
  padding: 0;
  color: $black;
  min-height: 100vh;
  @extend .u-flexed;
  @extend .u-align-stretch;
  @extend .u-flex-direction-column;
  background: $white none repeat scroll 0 0 !important;
}
html,
body {
  overscroll-behavior-y: none !important;
}

#root {
  margin: 0;
  padding: 0;
  background: $white none repeat scroll 0 0;
}
#root,
.full-page {
  min-height: 100vh;
  @extend .u-flexed;
  @extend .u-align-stretch;
  @extend .u-flex-direction-column;

  .header-horizontal,
  .footer-wrap main {
    flex: 0;
    flex-shrink: 0;
  }
  main {
    flex: 1;
  }
}

.app-wrapper {
  min-height: 100vh;
  overflow-x: hidden !important;
}

/* Includes
   ================================ */

@import "components/components";
@import "objects/objects";
@import "utility/utility";
