/* Forms
------------------------------------*/
.required {
  color: $red;
}
.w-20 {
  width: 20% !important;
  max-width: 20% !important;
}

.grecaptcha {
  &-text {
    a {
      color: $blue_sh2;
      border-bottom: 1px solid $blue_sh2;
      @include transition(all 0.3s ease-in);

      &:hover {
        border-bottom: 1px solid transparent;
      }
    }
  }
  &-badge {
    visibility: hidden;
    @include opacity(0);
  }
}

.form-group {
  @include margin-bottom(20);

  & label,
  legend {
    color: $black;
    cursor: pointer;
    @include font-size(18);
    font-weight: $weightMedium;
    @include margin-bottom(7);

    &.leftAlign {
      width: 100%;
      text-align: left;
    }
    &.rightAlign {
      width: 100%;
      text-align: left;
    }
  }
  .form-control {
    color: $black;
    width: $viewportSize;
    @include font-size(18);
    @include padding(13 15);
    border: 1px solid $border;
    @include border-radius(4px);
    font-weight: $weightRegular;
    @include transition(all 0.3s ease-out);
    @include box-shadow(0 2px 3px rgba($black, 0.05));

    &:focus {
      border: 1px solid $blue_sh1 !important;
      box-shadow: 0 0 0 3px rgba($blue_sh1, 0.25) !important;
    }

    &.medium {
      @include padding(10 15);
    }
  }
  textarea {
    &.form-control {
      resize: none;
      height: 120px;
    }
  }
  & .help-block {
    color: $white;
    border-radius: 5px;
    @include inline-flex;
    @include padding(2 6);
    @include font-size(14);
    @include margin-top(5);
    background-color: $red;
  }

  & .selectdropdown {
    height: 42px;
    position: relative;
    width: $viewportSize;

    & select {
      border: none;
      height: 42px;
      box-shadow: none;
      width: $viewportSize;
      background: transparent;
      background-image: none;
      -webkit-appearance: none;
      appearance: none;
      -ms-appearance: none;
      -o-appearance: none;
      @include padding(0 10);
      position: relative;
      border: 1px solid $offwhite;
      z-index: 3;
      &:focus {
        outline: none;
        border: 1px solid $blue_sh1;
      }
      option {
        color: $black;
        @include font-size(15);
        @include padding(14 0);
        font-weight: $weightRegular;
        border-bottom: 1px solid $offwhite !important;
      }
    }

    .form-control {
      overflow: hidden;
      text-overflow: ellipsis;
      @include padding-right(30);
    }

    &:after {
      z-index: 2;
      top: 52.5%;
      right: 14px;
      display: block;
      content: "\f078";
      @extend .if-solid;
      position: absolute;
      pointer-events: none;
      color: rgba($greyish, 0.5);
      @include transform(translateY(-50%));
    }

    &--withchosen {
      .chosen-container {
        z-index: 3;
        border: 1px solid $offwhite;
        width: 100% !important;
      }
      .chosen-container.chosen-container-active {
        border: 1px solid $blue_sh1;
      }

      .chosen-single div {
        display: none;
      }
      .chosen-single,
      .chosen-container-active.chosen-with-drop .chosen-single {
        background: transparent;
        border: none;
        @include padding-top(10);
        height: 42px;
        @include border-radius(0);
        @include box-shadow(none);
      }
      .chosen-container .chosen-drop {
        margin-top: 1px;
        margin-left: -1px;
        margin-right: -1px;
        width: calc(100% + 2px);
        @include box-shadow(none);
        @include border-radius(0);
        border: 1px solid $offwhite;
        border-top: 0px none;
      }
      .chosen-container .chosen-results {
        overflow: auto;
        max-height: 160px;
      }
      .chosen-container .chosen-results li {
        @include font-size(16);
        font-weight: $weightRegular;
        color: $greyish;
      }
      .chosen-container-single .chosen-single span {
        @include font-size(16);
        font-weight: $weightRegular;
        color: $black;
      }
      .chosen-container .chosen-results li {
        border-top: 1px solid $offwhite;
        @include padding(10 0);
        &:first-child {
          border-top: none;
        }
      }
      .chosen-container .chosen-results li.highlighted {
        color: $blue_sh2;
        background: $white;
      }
    }
  }

  &--has-error {
    & label {
      color: $red;
    }
    & .form-control {
      border: 1px solid $red;
      box-shadow: 0 0 0 3px rgba($red, 0.1) !important;

      &:focus {
        border: 1px solid $red !important;
        box-shadow: 0 0 0 3px rgba($red, 0.25) !important;
      }
    }
    & .help-block {
      color: $white;
      display: inline-block;
    }
  }

  &--has-success {
    & label {
      color: $success;
    }
    & .form-control {
      border: 1px solid $success;
      box-shadow: 0 0 0 3px rgba($success, 0.1) !important;

      &:focus {
        border: 1px solid $success !important;
        box-shadow: 0 0 0 3px rgba($success, 0.25) !important;
      }
    }
    & .help-block {
      color: $success;
    }
  }
  &--mb7 {
    @include margin-bottom(7);
  }

  .textareasize {
    &--smarll {
      height: 120px;
    }
    &--medium {
      height: 160px;
    }
    &--large {
      height: 220px;
    }
  }

  .uploadfilebox {
    $this: &;
    min-width: 300px;
    a {
      display: block;
      @include padding(12);
      @include font-size(12);
      font-weight: $weightBold;
      text-align: center;
      @include transition(all 0.3s ease-out);
      @include box-shadow(inset 0 0 1px $grey_sh1);
    }
    &--block {
      width: $viewportSize;
    }
    &--bgwhitered {
      a {
        background: $white;
        color: $error;
        border: 1px $offwhite solid;
        &:hover,
        &:focus {
          background: $error;
          color: $white;
          border-color: $error;
        }
      }
    }
  }

  fieldset {
    margin: 0;
    padding: 0;
    border: 0 none;
  }
  fieldset,
  legend {
    border: 0 none;
  }
}

.panel-body {
  .form-group {
    &:last-child,
    &:last-of-type,
    &:only-child,
    &:only-of-type {
      @include margin-bottom(4);
    }
  }
}

.chosen-container-single .chosen-search input[type="text"] {
  color: $black;
  min-height: 34px;
  @include font-size(16);
  font-family: $primaryFont;
  font-weight: $weightRegular;
}

.form-control::-webkit-input-placeholder {
  color: rgba($greyish, 0.5);
}
.form-control::-moz-placeholder {
  color: rgba($greyish, 0.5);
}
.form-control::-ms-input-placeholder {
  color: rgba($greyish, 0.5);
}
.form-control::-o-placeholder {
  color: rgba($greyish, 0.5);
}

/* Custom Radio and Checkboxes
------------------------------------- */
.form-wrap.text-center label.error {
  display: table;
  margin-top: 5px;
  text-align: left !important;
}

.button-set {
  width: 100%;
  position: relative;
  margin-bottom: 0px;
  @include flexbox();

  [class^="column"] {
    margin-right: 50px;
    display: inline-block;
    @media #{$media-phone} {
      display: block;
      margin-right: 0px;
    }
  }

  label {
    width: 100%;
    color: $black;
    cursor: pointer;
    margin-right: 0px;
    margin-bottom: 0px;
    @include font-size(18);
    line-height: 1.25;
    @include padding-left(30);
    font-weight: $weightRegular;
  }
  input {
    &[type="radio"],
    &[type="checkbox"] {
      margin: 0;
      height: 1px;
      @include padding(0);
      @include opacity(0);
      position: absolute;
      pointer-events: none;
    }
    &[type="radio"] + span {
      @include size(20px);
      text-align: center;
      display: inline-block;
      background-color: $white;
      border: 2px solid $border;
      vertical-align: top;
      position: relative;
      @include margin-right(10);
      @include transition(all 0.1s ease);
      @include border-radius(50%);
    }

    &[type="radio"] + span {
      top: 2px;
    }
    &[type="radio"] + span::after {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: " ";
      position: absolute;
      @include opacity(0);
      display: inline-block;
      @include transition(all 0.1s ease);
    }
    &[type="radio"]:focus + span,
    &[type="radio"]:active + span {
      border-color: $success;
    }
    &[type="radio"]:checked + span::after {
      @include opacity(1);
    }
    &[type="radio"].error + span {
      border-color: $error;
    }
    &[type="radio"] {
      + span {
        @include border-radius(50%);

        &::after {
          @include border-radius(4px);
          background-color: $blue;
          border-color: $blue;
        }
      }
      &:checked + span {
        border: 2px solid $blue;
        @include transition(all 0.1s ease);
      }
    }
    &[type="radio"]:checked + span::after {
      border: 3px solid $white;
      @include border-radius(50%);
    }
  }
}

form.contact-form .help-block-error {
  display: block;
}

.title {
  .button-set {
    label {
      color: $black;
    }
    input {
      &[type="radio"] + span,
      &[type="checkbox"] + span {
        border: 0px none;
      }
    }
  }
}

.button-set {
  .eds-checked {
    top: 0px;
    left: 0px;
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    @include padding-right(5);

    &:before {
      top: 0;
      left: 0;
      margin: 0;
      z-index: 1;
      content: "";
      display: block;
      position: absolute;
      @include opacity(0);
      @include size(24px, 24px);
      @include border-radius(100%);
      background-color: rgba($white, 0);
      @include transform(scale3d(2.3, 2.3, 1));
    }
  }
  .eds-checked .check {
    z-index: 1;
    overflow: hidden;
    position: relative;
    display: inline-block;
    background-color: $white;
    border: 1px solid $border;
    @include size(24px, 24px);
    @include border-radius(4px);
    @include box-shadow(0 2px 3px rgba($black, 0.05));

    &:before {
      content: "";
      display: block;
      margin-top: -1px;
      margin-left: 9px;
      position: absolute;
      @include size(0px, 0px);
      background: $blue_sh1 !important;
      @include transform(rotate(45deg));
      -webkit-animation: checkbox-off 0.3s forwards;
      -moz-animation: checkbox-off 0.3s forwards;
      -o-animation: checkbox-off 0.3s forwards;
      -ms-animation: checkbox-off 0.3s forwards;
      animation: checkbox-off 0.3s forwards;
    }
  }
  input[type="checkbox"] {
    &:focus + .eds-checked .check:after {
      @include opacity(0.2);
    }
    &:checked + .eds-checked .check {
      background: $blue_sh1 !important;
      border-color: darken($blue_sh1, 6%);

      &:before {
        color: $white;
        background: $blue_sh1 !important;
        -webkit-animation: checkbox-on 0.3s forwards;
        -moz-animation: checkbox-on 0.3s forwards;
        -ms-animation: checkbox-on 0.3s forwards;
        -o-animation: checkbox-on 0.3s forwards;
        animation: checkbox-on 0.3s forwards;
        -webkit-box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px,
          -5px 5px 0 10px, 20px -12px 0 11px;
        -moz-box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px,
          -5px 5px 0 10px, 20px -12px 0 11px;
        -ms-box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px,
          -5px 5px 0 10px, 20px -12px 0 11px;
        -o-box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px,
          -5px 5px 0 10px, 20px -12px 0 11px;
        box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px,
          20px -12px 0 11px;
      }
    }
    &[disabled] {
      ~ .eds-checked .check {
        border-color: $blue_sh1;
        @include opacity(0.26);
      }
      + .eds-checked .check:after {
        @include transform(rotate(-45deg));
        background-color: rgba($blue_sh1, 1) !important;
      }
    }
  }
}

.button-set input[type="checkbox"]:checked + .eds-checked:before,
.button-set input[type="radio"]:checked + .eds-checked:before {
  -webkit-animation: rippleOn 500ms;
  -moz-animation: rippleOn 500ms;
  -o-animation: rippleOn 500ms;
  -ms-animation: rippleOn 500ms;
  animation: rippleOn 500ms;
  background-color: rgba($blue_sh1, 0.3);
}
.button-set input[type="checkbox"]:checked + .eds-checked .check:after,
.button-set input[type="radio"]:checked + .eds-checked span:after {
  -webkit-animation: rippleOn 500ms forwards;
  -moz-animation: rippleOn 500ms forwards;
  -o-animation: rippleOn 500ms forwards;
  -ms-animation: rippleOn 500ms forwards;
  animation: rippleOn 500ms forwards;
}
.button-set input[type="checkbox"]:not(:checked) + .eds-checked:before,
.button-set input[type="radio"]:not(:checked) + .eds-checked:before {
  -webkit-animation: rippleOff 500ms;
  -moz-animation: rippleOff 500ms;
  -o-animation: rippleOff 500ms;
  -ms-animation: rippleOff 500ms;
  animation: rippleOff 500ms;
  background-color: rgba($blue_sh1, 0.3);
}

@keyframes checkbox-on {
  0% {
    -webkit-box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px,
      -5px 5px 0 10px, 15px 2px 0 11px;
    -moz-box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px,
      15px 2px 0 11px;
    -ms-box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px,
      15px 2px 0 11px;
    -o-box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px,
      15px 2px 0 11px;
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px,
      15px 2px 0 11px;
  }
  50% {
    -webkit-box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px,
      -5px 5px 0 10px, 20px 2px 0 11px;
    -moz-box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px,
      20px 2px 0 11px;
    -ms-box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px,
      20px 2px 0 11px;
    -o-box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px,
      20px 2px 0 11px;
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px,
      20px 2px 0 11px;
  }
  100% {
    -webkit-box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px,
      -5px 5px 0 10px, 20px -12px 0 11px;
    -moz-box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px,
      20px -12px 0 11px;
    -ms-box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px,
      20px -12px 0 11px;
    -o-box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px,
      20px -12px 0 11px;
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px,
      20px -12px 0 11px;
  }
}
@keyframes rippleOn {
  0% {
    @include opacity(0);
  }
  50% {
    @include opacity(0.2);
  }
  100% {
    @include opacity(0);
  }
}
@keyframes rippleOff {
  0% {
    @include opacity(0);
  }
  50% {
    @include opacity(0.2);
  }
  100% {
    @include opacity(0);
  }
}

.radio {
  height: auto;
  @include flexbox;
  @include align-items(center);

  &.column {
    @include flex-direction(column, vertical);
  }

  label {
    color: $black;
    height: auto;
    cursor: pointer;
    font-weight: 400;
    margin-bottom: 0;
    position: relative;
    @include padding-left(26);
    display: inline-block;
    @include font-size(18);
    line-height: 1.5;
    font-weight: $weightMedium;
    text-transform: inherit !important;
    max-width: 100%;
    @include flex(0 0 100%);

    strong {
      color: $black_sh1;
      @include padding-left(4);
    }

    span.text-green,
    a {
      @include padding-left(4);
    }

    &:before {
      left: 0;
      top: 0.25rem;
      content: " ";
      background-color: $white;
      border-radius: 50%;
      border: 2px solid $border;
      display: inline-block;
      @include margin-left(0);
      position: absolute;
      @include transition(border 0.5s ease-in-out);
      @include size(20px);
      outline: 0 !important;
    }

    &:after {
      left: 4px; /* 4px */
      top: 0.5rem; /* 8px */
      -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      -ms-transform: scale(0, 0);
      -o-transform: scale(0, 0);
      -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      -webkit-transform: scale(0, 0);
      -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      background-color: #6c757d;
      border-radius: 50%;
      content: " ";
      display: inline-block;
      @include margin-left(0);
      position: absolute;
      transform: scale(0, 0);
      transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33),
        -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      @include size(12px);
    }
  }

  input[type="radio"] {
    z-index: 1;
    cursor: pointer;
    @include opacity(0);
    outline: 0 !important;
    position: absolute;
    @include size(1px);
  }
  input[type="radio"]:disabled + label {
    @include opacity(0.65);
  }
  input[type="radio"]:focus + label::before {
    outline-offset: -2px;
    outline: 5px auto -webkit-focus-ring-color;
    outline: thin dotted;
  }
  input[type="radio"]:checked + label::after {
    @include transform(scale(1, 1));
  }
  input[type="radio"]:disabled + label::before {
    cursor: not-allowed;
  }

  &-right {
    label {
      padding-left: 0px;
      padding-right: 26px;

      &:before {
        top: 1px;
        right: 0;
        left: auto;
        margin-left: 0;
        margin-right: 0px;
        position: absolute;
        @include transition(border 0.5s ease-in-out);
        width: 18px;
        outline: 0 !important;
      }
      &:after {
        top: 5px;
        left: auto;
        right: 4px;
        margin-left: 0px;
        margin-right: 0px;
      }
    }
    input[type="radio"] {
      position: absolute;
    }
  }
}

.radio.radio-inline {
  margin-top: 0;
}

.radio.radio-single label {
  height: 17px;
}

.radio-primary {
  input[type="radio"] + label {
    &::before {
      border-color: $border;
    }
    &::after {
      background-color: $blue;
    }
  }
  input[type="radio"]:checked + label {
    color: $blue;
    strong {
      color: $blue;
    }
    &::before {
      border-color: $blue;
    }
    &::after {
      background-color: $blue;
    }
  }
}

/* Vertical Form */
.vertical-form {
  .form-group {
    width: 100%;
    margin-bottom: 15px;

    &.no-margin-bottom {
      margin-bottom: 0px;
    }

    input[type="text"],
    input[type="password"],
    input[type="tel"],
    input[type="email"],
    input[type="search"],
    input[type="url"],
    input[type="number"],
    select,
    textarea {
      display: block;
      width: 100%;
      @include padding(7 0);
      outline: none;
      @include font-size(13);
      line-height: 1.54;
      font-weight: $weightMedium;
      color: $greyish;
      height: auto;
      text-align: left;
      font-family: $primaryFont;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: 0;
      @include border-radius(0px);
      border-bottom: 1px solid $offwhite;
      @include transition(all 0.3s ease-out);
      box-shadow: none;
    }

    .input--type {
      position: relative;

      &--icon {
        position: absolute;
        top: 50%;
        right: 0;
        @include translate(0, -50%);
        color: $grey;
      }
    }

    .forgot--password {
      &--link {
        color: $greyish;
        font-family: $weightRegular;
        @include font-size(11);
        line-height: normal;
        @include margin-bottom(10);
        letter-spacing: normal;
        font-family: $primaryFont;
        display: block;
        font-style: italic;
        opacity: 0.8;
        text-decoration: underline;
      }
    }
    .custom-select {
      -webkit-appearance: none !important;
      -moz-appearance: none !important;
      appearance: none !important;

      &:after {
        content: "\f107";
        font-family: $iconfont;
        position: absolute;
        right: 15px;
        top: 5px;
        display: inline-block;
      }
    }
  }

  .o-modal--action {
    @include margin-top(30);
  }

  .o-modal--subaction {
    @include margin-top(10);
  }

  .help-block {
    display: none;
  }

  .has-success {
    .input--type--icon {
      color: $blue;
    }
  }

  .has-error {
    .input--type--icon {
      color: $error;
    }
  }
}

.c-formrequirement {
  @include font-size(12);

  color: $greyish;
  &__head {
    line-height: 1.5;
    font-weight: $weightBold;
  }
  &__list {
    li {
      font-weight: $weightRegular;
      font-style: italic;
      line-height: 1.5;
    }
  }
}

/* Date RangePicker */
.datepicker {
  &.datepicker-dropdown {
    &.dropdown-menu {
      @include padding(6);
      @include font-size(14);
      font-family: $primaryFont;
      @include box-shadow(none);
    }
  }
  table tr td {
    height: 27px;
  }
  table tr td,
  table tr th {
    vertical-align: middle;
    font-family: $primaryFont;

    &.active {
      color: $white;
      border-color: $blue;
      background-color: $blue;
      &.active {
        color: $white;
        border-color: $blue;
        background-color: $blue;
      }
    }
  }
}

.daterangepicker {
  border-color: $border;
  @include margin-top(-1);
  font-family: $primaryFont;
  @include border-radius(4px);

  td {
    &.active {
      color: $white;
      background-color: $blue;
      &:hover {
        color: $white;
        background-color: $blue;
      }
    }

    &.in-range {
      background-color: $blue_sh3;
    }

    &.active {
      &.end-date {
        &.available {
          background-color: $blue;
        }
      }
      &.available {
        &:hover {
          color: $white;
          background-color: $blue_sh1;
        }
      }
    }
  }

  .calendar-table {
    td {
      height: 26px;
      @include font-size(13);
      line-height: 26px;
      font-family: $primaryFont;
    }
  }

  .applyBtn {
    color: $white;
    border-color: $blue;
    background-color: $blue;

    &:hover {
      color: $white;
      border-color: $blue_sh1;
      background-color: $blue_sh1;
    }
  }
}

.c-formnotice {
  @include font-size(15);
  color: $greyish;
  line-height: 1.47;
  &--medium {
    font-weight: $weightMedium;
  }
}

.office-form-matrix-group {
  .office-form-matrix-header,
  .office-form-matrix-row {
    @include flexbox;
    @include padding(0);

    .office-form-matrix-cell {
      outline: none;
      @include flexbox;
      word-break: break-word;
      @include padding(10 0 15);
      overflow-wrap: break-word;
      @include align-items(flex-end);

      color: $black;
      cursor: pointer;
      @include font-size(18);
      font-weight: $weightMedium;

      &:first-child {
        width: 100%;
        max-width: 250px;
        @include padding(15);
      }

      &:not(:first-child) {
        @include flex(1);
        text-align: center;
        @include justify-content(center);
      }
    }
  }
  .office-form-matrix-row {
    background-color: $offwhite;
    @include align-items(center);

    &:nth-child(2n + 1) {
      background-color: darken($offwhite, 5%);
    }

    .office-form-matrix-cell {
      &:not(:first-child) {
        cursor: pointer;
        @include flex(1);
        text-align: center;
        @include justify-content(center);
      }

      .radio {
        top: -13px;
      }
      .button-set {
        label {
          padding-left: 0;
        }
        input[type="radio"] + span {
          margin-right: 0;
          border-color: darken($border, 5%);
        }
        input[type="radio"]:checked + span {
          border-color: $blue;
        }
      }

      input[type="radio"] {
        cursor: pointer;
        @include size(24px);
      }
    }
  }
  .office-form-matrix-header {
    background: $white;
  }

  @media (max-width: 767px) {
    @include flexbox;
    flex-wrap: nowrap;
    overflow-x: scroll;
    @include flex-direction(column, vertical);

    .office-form-matrix-header,
    .office-form-matrix-row {
      position: relative;

      .office-form-matrix-cell {
        width: 150px !important;
        min-width: 150px !important;
        white-space: normal !important;

        &:first-child {
          width: 200px !important;
          min-width: 200px !important;
          white-space: normal !important;
        }
      }
    }
    .office-form-matrix-row {
      &::before {
        top: 0;
        left: -200%; /* Don't change this */
        right: -200%; /* Don't change this */
        content: " ";
        height: 100%;
        position: absolute;
        background-color: $offwhite;
        /*top: -200px;
        bottom: -200px;*/
      }
      &:nth-child(2n + 1) {
        &::before {
          background-color: darken($offwhite, 5%);
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .form-group {
    [class^="col-"] {
      &:first-child,
      &:first-of-type {
        @include margin-bottom(20);
      }

      &:only-child,
      &:only-of-type,
      &:last-child,
      &:last-of-type {
        @include margin-bottom(0);
      }
    }
  }
}
