/* Begin links view style */
.u-sf {
  font-family: $secondaryFont;
}

.u-upper {
  text-transform: uppercase;
}
.no-pointer {
  pointer-events: none !important;
}
.all-pointer {
  pointer-events: all !important;
}
.pointer {
  cursor: pointer !important;
}
.not-allowed {
  cursor: not-allowed !important;
}
.o-hidden {
  overflow: hidden !important;
}

.left-0px {
  left: 0;
}
.top-0px {
  top: 0;
}
.top-2px {
  top: 2px;
}
.top-4px {
  top: 4px;
}
.top-6px {
  top: 6px;
}

.vc {
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
}
.flex-full {
  @include flexbox;
  @include align-items(center);
  @include flex-direction(row, horizontal);
}

.round-full {
  @include border-radius(100% !important);
}
.radius-lg {
  @include border-radius(20px);
}
.radius-xl {
  @include border-radius(40px);
}

.h-100,
.h-full {
  height: 100% !important;
}

.w-full,
.w-100 {
  width: 100% !important;
}
.mw-full,
.mw-100 {
  max-width: 100% !important;
}

.w-65 {
  max-width: 65%;
}

@media (max-width: 767px) {
  .sm\:w-100 {
    max-width: 100%;
  }
  .sm\:mmb-40 {
    margin-bottom: 40px;
  }
  .sm\:mmb-50 {
    margin-bottom: 50px;
  }
}

@media (max-width: 991px) {
  .mw-450px {
    max-width: 450px !important;
    margin-inline: auto !important;
  }
  .mxx-auto {
    margin-inline: auto !important;
  }
}

.ml-1 {
  margin-left: 5px !important;
}
.ml-2 {
  margin-left: 10px !important;
}

.mr-1 {
  margin-right: 5px !important;
}
.mr-2 {
  margin-right: 10px !important;
}

.mb-10 {
  margin-bottom: 100px !important;

  @media (max-width: 767px) {
    margin-bottom: 75px !important;
  }
}

.no-padding {
  padding: 0 !important;
}
.no-margin {
  margin: 0 !important;
}

.pr-3 {
  padding-right: 1.25rem;
}
.w-70 {
  width: 100%;
  max-width: 70% !important;
}
.w-30 {
  width: 100%;
  max-width: 30% !important;
}

.u-image-covered {
  @include image_background();
}
.u-advertisement-section {
  text-align: center;
  &--m80 {
    margin: 80px 0;
  }
}

.u-hr {
  width: 100%;
  margin: 36px auto;
  @include flexbox;

  hr {
    margin: 0px;
    width: 100%;
    min-height: 1px;
    border: 0px none;
    border-top: 1px solid rgba($black, 0.1);
  }
}

// z Index(s)
.z-1 {
  z-index: 1;
}
.z-2 {
  z-index: 2;
}
.z-3 {
  z-index: 3;
}
.z-4 {
  z-index: 4;
}
.z-5 {
  z-index: 5;
}
.z-6 {
  z-index: 6;
}
.z-7 {
  z-index: 7;
}
.z-8 {
  z-index: 8;
}
.z-9 {
  z-index: 9;
}
.z-10 {
  z-index: 10;
}
.z-20 {
  z-index: 20;
}
.z-30 {
  z-index: 30;
}
.z-40 {
  z-index: 40;
}
.z-50 {
  z-index: 50;
}
.z-60 {
  z-index: 60;
}
.z-70 {
  z-index: 70;
}
.z-80 {
  z-index: 80;
}

// Backdrop
.backdrop {
  &-xs {
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
  }
  &-sm {
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }
  &-md {
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  }
  &-lg {
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
  }
  &-xl {
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
  }
}

.blur {
  @extend .w-full;
  -webkit-filter: blur(20px);
  filter: blur(20px);
  transition: filter 0.6s, -webkit-filter 0.6s;
}
.blur.lazy-load-image-loaded {
  -webkit-filter: blur(0);
  filter: blur(0);
}

.fw {
  &-normal,
  &-regular {
    font-weight: $weightRegular !important;
  }
  &-medium {
    font-weight: $weightMedium !important;
  }
  &-semibold {
    font-weight: $weightSemibold !important;
  }
  &-bold {
    font-weight: $weightBold !important;
  }
  &-black {
    font-weight: $weightBlack !important;
  }
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}
@media (max-width: 767px) {
  .container,
  [class^="col-"] {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .row {
    margin-left: -20px !important;
    margin-right: -20px !important;

    > * {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
  }
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 96%;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 95%;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1150px;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1250px;
  }
}

.col-centered {
  float: none;
  margin-left: auto;
  margin-right: auto;
}

.x-close {
  top: 50%;
  right: 0;
  z-index: 50;
  border: none;
  cursor: pointer;
  position: absolute;
  @include size(16px);
  background: transparent;
  @include transform(translateY(-50%));
  @include transition(all 0.4s ease-in-out);
  &:before,
  &:after {
    top: 50%;
    left: -15%;
    right: -15%;
    height: 1px;
    content: " ";
    position: absolute;
    background-color: lighten($black, 20%);
    @include transition(all 0.2s ease-in-out);
  }
  &:before {
    @include transform(rotate(45deg));
  }
  &:after {
    @include transform(rotate(-45deg));
  }
  &:hover {
    /*@include transform(translateY(-50%) rotate(90deg)!important);*/
    &:before,
    &:after {
      background-color: $blue;
    }
  }
}

.b-ads-space {
  &.vertical {
    @include flexbox;
    @include padding(0);
    @include margin-bottom(30);
    @include align-items(center);
    @include justify-content(center);

    @include responsive(sm) {
      @include padding(0);
      @include margin-bottom(50);
    }
  }

  &.horizontal {
    @include flexbox;
    @include padding(0);
    @include margin-bottom(30);
    @include align-items(center);
    @include justify-content(center);

    @include responsive(sm) {
      @include padding(0);
      @include margin-bottom(50);
    }

    img,
    iframe,
    object,
    video {
      width: 100%;
      max-width: 769px;
    }
  }
}

/*! Don't Remove it */

.desktop-hidden,
.mobile-hidden {
  display: none !important;
}
@media (min-width: 768px) {
  .desktop-hidden {
    display: inline-flex !important;
  }
}

@media (max-width: 767px) {
  .mobile-hidden {
    display: inline-flex !important;
  }
  .img-fluid {
    width: 100%;
  }
}

.dropdown-menu {
  border: 0px none;
  @include padding(20);
  @include border-radius(0);
  background-color: $offwhite;
  @include box-shadow(0 2px 4px 1px rgba(89, 88, 89, 0.2));

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    a {
      display: block;
      color: $greyish;
      @include opacity(0.7);
      @include font-size(14);
      line-height: 1.5;
      @include padding(2 0);
      font-weight: $weightRegular;

      &:hover,
      &:active {
        color: $blue_sh1;
        font-weight: $weightMedium;
      }
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    margin: 7px auto;
    display: inline-block;
    background-color: rgba($greyish, 0.5);
  }

  &.pull-right {
    right: 0;
    left: auto;
  }
}

.tab-content > .active {
  display: block;
  opacity: 1;
}

.cursor-pointer {
  cursor: pointer;
}

.clamp2 {
  display: block;
  overflow: hidden;
  min-height: 2rem;
  position: relative;
  white-space: normal;
  display: -webkit-box;
  @include line-clamp(2);
  text-overflow: ellipsis;
  max-height: 4.15rem !important;
  @include flex-direction(column, vertical);

  @media (max-width: 767px) {
    max-height: 3.25rem !important;
  }
}

.radius-sm {
  border-radius: 0.5rem;
}

@media (max-width: 767px) {
  .sm\:mmb-5 {
    margin-bottom: 2.125rem !important;
  }
}

.easing {
  @include transition(all 0.3s ease-in);
}
.inner-page {
  padding-top: 80px;
}

.rotate-\[5deg\] {
  --rotate: 5deg;
  -webkit-transform: rotate(var(--rotate));
  -moz-transform: rotate(var(--rotate));
  transform: rotate(var(--rotate));
}

.rotate-\[-5deg\] {
  --rotate2: -5deg;
  -webkit-transform: rotate(var(--rotate2));
  -moz-transform: rotate(var(--rotate2));
  transform: rotate(var(--rotate2));
}

.gap-4 {
  gap: 0.5rem !important;

  @media (max-width: 767px) {
    gap: 0.375rem !important;
  }
}
/* Begin links view style */
