/* Mobile Menu */

@media (min-width: 992px) {
  .responsive--menu {
    display: none !important;
  }
  .c-navigation {
    &--list {
      li {
        span.plus {
          display: none !important;
        }
      }
    }
  }
}

.responsive--menu {
  left: 0;
  top: 67px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-align: left;
  z-index: 99999999;
  max-height: calc(100% - 67px);
  padding: 10px 0px 40px !important;
  background-color: $mobilemenu;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
  @include transition(all 0.3s ease);
  position: fixed !important;

  &:after {
    /*fixscroll overflow hidden android*/
    content: "";
    display: block;
    position: fixed;
    width: 0;
    height: 0;
    left: 0;
    bottom: 0;
  }

  &.is--active {
    @include transform(translateX(0));
  }

  .mainNav {
    margin: 0;

    li {
      margin: 0;
      padding: 0;
      width: 100%;
      height: auto;
      @include flexbox;
      position: relative;
      border-bottom: 1px solid rgba($white, 1);
      @include flex-direction(column, vertical);

      &:before {
        display: none;
      }

      &:first-child {
        border-top: 0px none;
      }

      a {
        width: 100%;
        color: $blue;
        display: block;
        font-size: 1.125rem;
        @include padding(12 20);
        border: 0px none !important;
        text-align: left !important;
        @include border-radius(0px);
        text-decoration: none !important;

        &:hover,
        &:focus,
        &:active {
          color: $blue;
          background-color: rgba($white, 0.5);
        }
        &.active {
          color: $blue;
          font-weight: $weightBold;
        }
      }
      span.plus {
        top: 0;
        right: 0;
        z-index: 9;
        color: $black;
        cursor: pointer;
        font-size: 20px;
        @include flexbox;
        text-align: center;
        position: absolute;
        @include size(50px);
        @include align-items(center);
        @include justify-content(center);
        @include transition(all 0.3s ease);
      }
    }
  }

  .sub-menu {
    margin: 0;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    z-index: 99999999;
    @include padding(0);
    background-color: $mobilemenu;
    @include transition(all 800ms);

    li {
      border-top: 1px solid $mobilemenu;

      &:first-child {
        border-top: 1px solid $mobilemenu;
      }

      a {
        color: $black;
        position: relative;
        font-size: 1.125rem;
        @include padding(12 40);

        &:hover,
        &:focus,
        &:active {
          color: $blue;
          background-color: rgba($white, 0.5);
        }
        &.active {
          color: $blue;
          font-weight: $weightBold;
        }
      }
    }
    &.is--active {
      height: auto;
      max-height: 500px;
    }
  }
}

.responsive--menu .mainNav li {
  &.is--active {
    background-color: $white;

    span.plus {
      @include transform(rotate(90deg));
    }
    .sub-menu {
      background-color: $white;
    }
  }
}

/* End Mobile Menu */
