.error {
  $selfError: &;
  padding: 50px 0;

  &_main {
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    @include flex-direction(column, vertical);
    min-height: calc(100vh - 100px);
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    width: 90%;

    &_logo {
      margin-bottom: 40px;
    }
    &_title {
      color: $white;
      @include font-size(60);
      text-transform: uppercase;
    }
    p {
      color: #b7b4c7;
    }

    &_action {
      margin-top: 50px;
      @include flexbox;
      @include flex-direction(column, vertical);
      width: 100%;

      .button {
        min-width: 250px;
        height: 50px;
        @include font-size(20);
        font-weight: $weightBold;
        margin-bottom: 20px;
      }

      .input-group {
        .form-control {
          background-color: transparent;
          border: 2px solid $white;
          border-right: 0;
          height: 50px;
          outline: none;
          box-shadow: none;
          color: $white;
          @include font-size(20);
        }
        .input-group-text {
          background-color: transparent;
          border: 2px solid $white;
          border-left: 0;
          padding: 0.375rem 2rem 0.375rem 0.75rem;
          outline: none;
        }
      }
    }
  }

  @include responsive(xs) {
    @include respond-to(md) {
      height: 100%;
      min-height: calc(100vh - 100px);
      #{$selfError} {
        &_main {
          &_action {
            @include flex-direction(row, horizontal);

            .button {
              margin-right: 20px;
              margin-bottom: 0;
              height: 80px;
            }

            .input-group {
              .form-control {
                height: 80px;
              }
            }
          }

          &_title {
            @include font-size(100);
          }
        }
      }
    }
  }
}
