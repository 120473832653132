$primary: #25cf7b !default;
$secondary: #1b1924 !default;
$tertiary: #001835 !default;

$greyish: #b7b4c7 !default;
$grey: rgba(89, 88, 89, 0.7) !default;
$grey_sh1: rgba(89, 88, 89, 0.5) !default;
$black: #000000 !default;
$black_sh1: #1b1924 !default;
$transparent: rgba(0, 0, 0, 0) !default;

$blue: #302d75 !default;
$blue_sh1: #363544 !default;
$blue_sh2: #003265 !default;
$blue_sh3: #000013 !default;
$blue_sh4: #d9e9ff !default;

$red: #f23a58 !default;
$red_sh1: #d5012c !default;
$red_sh2: #daafff !default;
$error: $red !default;
$danger: $red !default;
$success: #25cf7b !default;
$warning: #f7b149 !default;
$yellow: #fbd362 !default;
$yellow_sh2: #e4c254 !default;
$yellow_sh3: #e07b0b !default;
$yellow_sh4: #ae1e09 !default;
$info: #0ecff8 !default;

$white: #ffffff !default;
$white_sh1: #e1e1e1 !default;
$white_sh2: #bacbe4 !default;
$footer: #010012 !default;
$footer_sh1: #1d1a27 !default;
$offwhite: #f7f8f9 !default;
$border: #c7cfd8 !default;
$hoverColor: $primary !default;
$unsuccessful: #ff3569 !default;
$blackred: #ac001a !default;
$purple: #120322 !default;

$gradient-1: $primary !default;
$gradient-2: #3da573 !default;
$gradient-3: #03fc81 !default;

$black_sh1: #333333 !default;
$black_sh2: lighten(
  $black_sh1,
  20%
) !default; /* color code == #666666 also (0,0,0,0.6) */
$black_sh3: lighten($black_sh1, 40%) !default; /* color code == #999999 */
$black_sh4: lighten($black_sh1, 60%) !default; /* color code == #cccccc */
$black_sh5: lighten($black_sh1, 41%) !default; /* color code == #9c9c9c */

// Social Color(s)
$facebook: #3b5998 !default;
$twitter: #00aeef !default;
$pinterest: #bd081c !default;
$googleplus: #e34829 !default;
$youtube: #d62423 !default;
$vimeo: #5bc7ff !default;
$instagram: #2c5987 !default;
$linkedin: #1f7bc2 !default;
$flickr: #377ede !default;
$lightgrey: #cdcdcd !default;
$lightborder: #e0e0e0 !default;
$mobilemenu: #f8f9fa !default;

/* Widths ▸ Window Width */
$viewportSize: 100%;
$max-width: 1920px;

// Dimensions

$screen-xs: 767px !default;

//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs !default;

//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm: 991px !default;
$screen-sm-min: $screen-sm !default;

//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md: 992px !default;
$screen-md-min: $screen-md !default;

//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop: $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg: 1200px !default;
$screen-lg-min: $screen-lg !default;

//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop: $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;

/* Media Query Breakpoints */
$media-desktop: "screen and (max-width: 1199px)";
$media-ipadPortrait: "screen and (max-width :991px)";
$media-iPad: "screen and (min-width : 768px) and (max-width : 991px)";
$media-ipadSmall: "screen and (max-width :767px)";
$media-tablet: "screen and (max-width: 575px)";
$media-phone: "screen and (max-width : 480px)";
