/* Layout --> Generic Banner
----------------------------- */

.generic_innerBanner {
    $genericInnerBanner: &;
    text-align: center;
    margin-bottom: 130px;
    &__label {
        @include font-size(24);
        font-weight: $weightBold;
        margin-bottom: 15px
    }
    &__title {
        @include font-size(25);
        font-weight: $weightBold;
        margin-bottom: 40px;

        span {
            display: block;
        }
    }

    &__des {
        @include font-size(20);
        font-weight: $weightLight;
        max-width: 770px;
        width: 90%;
        margin: 0 auto;
    }

    @include responsive(xs) {
        @include respond-to(md) {
            #{$genericInnerBanner} {
                &__label {
                    @include font-size(28);
                }
                &__title {
                    @include font-size(40);
                }
            }
        }

        @include respond-to(lg) {
            #{$genericInnerBanner} {
                &__label {
                    @include font-size(35);
                }
                &__title {
                    @include font-size(60);
                }
            }
        }
    }
}
