/* Begin Home style */
[class*="-section"] {
  > .container,
  > .container-fluid {
    width: 100%;
    max-width: 100%;
    @include padding-left(50);
    @include padding-right(50);

    @media (max-width: 991px) {
      @include padding-left(30);
      @include padding-right(30);
    }
  }
}

.home-section {
  width: 100%;
  min-height: 100vh;
  max-height: 850px;
  background-color: $white;
  background-image: url("../../../../assets/images/background/main-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;

  @media (max-width: 1024px) {
    max-height: inherit;
  }

  &-wrap {
    position: relative;
    @include padding-top(140);
    @include padding-bottom(80);
    display: flex;
    align-items: center;
    min-height: 100vh;
    max-height: 850px;

    @media (max-width: 1024px) {
      @include padding-top(140);
      @include padding-bottom(60);
      min-height: inherit;
      max-height: inherit;
    }
  }
}

//
.features-section,
.social-section {
  z-index: 3;
  position: relative;

  p:not(.text-sm) {
    @extend .fs-18;
  }
}

.features-section {
  background-color: $white;
  @include padding-top(100);
  @include padding-bottom(100);

  @media (max-width: 767px) {
    @include padding-top(60);
    @include padding-bottom(60);
  }
}

.section-head {
  position: relative;
  @extend .u-flexed;
  @extend .u-align-center;
  @extend .u-flex-direction-column;
  @include margin-bottom(40);

  @media (max-width: 991px) {
    justify-content: center;
  }

  .title {
    color: $black;
    font-size: clamp(2.75rem, 2.5rem + 1.75vw, 3.75rem);
    line-height: 1;

    // @media (max-width: 767px) {
    //   font-size: 50px;
    // }
  }
  p {
    max-width: 850px;
    margin-left: auto;
    margin-right: auto;
    font-size: clamp(1.5rem, 1.25rem + 0.25vw, 1.5rem);
    line-height: 1.35;
    color: $blue_sh1;
  }
}

.icon-box-wraper {
  border-radius: 0.5rem;
  background-color: $white;
  @include box-shadow(0 0 60px 0 rgba($black, 0.1));
  @include padding(30);
  @include transition(all 1s);
  height: calc(100% - 30px);
  @include margin-bottom(30);
  position: relative;

  .icon-media {
    @include size(100px);
    margin: 0 auto;
    @extend .vc;
    margin-bottom: 1.25rem;
  }

  h3 {
    // @include font-size(30);
    font-size: clamp(1.5rem, 1.25rem + 0.8651vw, 2.5rem);
    line-height: 1.5;
  }
  p {
    line-height: 1.5;
    color: $blue_sh1;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &:hover {
    @include transform(translateY(-15px));
  }
}

// Social Section
.social-section {
  @include padding-top(40);
  @include padding-bottom(40);
  background-color: $footer;

  @media (max-width: 767px) {
    @include padding-top(35);
    @include padding-bottom(35);
  }

  .capy-social {
    max-width: 100%;
    position: relative;

    @media (min-width: 1200px) {
      left: -20px;
    }
  }

  .social-content {
    width: 100%;
    text-align: center;

    .cta-button {
      text-align: center;

      @media (max-width: 991px) {
        @include margin-top(10);
      }

      .button-social {
        color: $black;
        @include size(80px);
        background-color: $white;

        &::before {
          background-color: rgba($primary, 0.2);
        }
        &:hover,
        &:focus,
        &:active {
          color: $primary;
        }
      }
    }

    @media (min-width: 1200px) {
      .cta-button {
        @include margin-top(20);
      }
    }

    h2,
    h4 {
      text-align: center;
    }

    h4 {
      color: $white;
      font-size: clamp(1.5rem, 1.25rem + 0.8651vw, 2.5rem);
      line-height: 1.65;
      text-shadow: 0 2px 0 rgba($black, 0.25);
    }
  }
}

.error_main {
  font-size: 50px;
  font-weight: $weightBold;
  @extend .text-gradient;
}

.fs-18 {
  font-size: clamp(1.25rem, 1.125rem + 0.25vw, 1.25rem);
  line-height: 1.65;
  font-weight: $weightRegular;
}

.blur {
  @extend .w-full;
  -webkit-filter: blur(20px);
  filter: blur(20px);
  transition: filter 0.6s, -webkit-filter 0.6s;
}
.blur.lazy-load-image-loaded {
  -webkit-filter: blur(0);
  filter: blur(0);
}
.bg-opaque {
  background-color: rgba($red_sh2, 0.1);
}

@media (min-width: 992px) {
  .pl-md-4 {
    @include padding-left(40);
  }
}

.text-grey {
  color: $greyish;
}
.text-gold {
  color: $yellow;
}
.text-black {
  color: $black !important;
}

.text-sm {
  @include font-size(16);
  line-height: 1.5;

  @include responsive(sm) {
    @include font-size(17);
    line-height: 1.5;

    @include respond-to(md) {
      @include font-size(18);
      line-height: 1.5;

      @include respond-to(lg) {
        @include font-size(20);
        line-height: 1.5;
      }
    }
  }
}
.text-md {
  @include font-size(24);
  line-height: 1.25;

  @include responsive(sm) {
    @include font-size(28);
    line-height: 1.25;

    @include respond-to(md) {
      @include font-size(32);
      line-height: 1.25;

      @include respond-to(lg) {
        @include font-size(35);
        line-height: 1.25;
      }
    }
  }
}
.text-lg {
  @include font-size(32);
  line-height: 1.25;

  @include responsive(sm) {
    @include font-size(35);
    line-height: 1.25;

    @include respond-to(md) {
      @include font-size(40);
      line-height: 1.25;

      @include respond-to(lg) {
        @include font-size(45);
        line-height: 1.25;
      }
    }
  }
}
.text-xl {
  @include font-size(45);
  line-height: 1.25;

  @include responsive(sm) {
    @include font-size(50);
    line-height: 1.25;

    @include respond-to(md) {
      @include font-size(60);
      line-height: 1.25;

      @include respond-to(lg) {
        @include font-size(70);
        line-height: 1.25;
      }
    }
  }
}

// Keyframes
@keyframes marque {
  0% {
    @include transform(translateX(0%));
  }
  100% {
    @include transform(translateX(-100%));
  }
}

@keyframes lambo {
  0% {
    transform: rotate(0.5deg) scale(1) translate(0%, 1%);
  }
  50% {
    transform: rotate(-0.5deg) scale(1) translate(0%, 1%);
  }
  100% {
    transform: rotate(0.5deg) scale(1) translate(0%, 1%);
  }
}

@keyframes bounce {
  0% {
    transform: rotate(0.5deg) scale(1) translate(0%, 1%);
  }
  50% {
    transform: rotate(-0.5deg) scale(1.01) translate(0%, 1%);
  }
  100% {
    transform: rotate(0.5deg) scale(1) translate(0%, 1%);
  }
}

@media (max-width: 991px) {
  .flex-100 {
    flex: 1;
  }
  .sm\:flex-row {
    @include flex-direction(row, horizontal);
  }
  .sm\:flex-col {
    @include flex-direction(column, vertical);
  }
  .sm\:align-start {
    align-items: flex-start !important;
  }
  .sm\:justify-start {
    justify-content: flex-start !important;
  }
  .sm\:mmb-2 {
    margin-bottom: 1rem !important;
  }
  .sm\:mmb-5 {
    margin-bottom: 2rem !important;
  }
  .sm\:mmb-50 {
    margin-bottom: 3.5rem !important;
  }
  .sm\:mml-0 {
    margin-left: 0 !important;
  }
  .sm\:mmt-0 {
    margin-top: 0rem !important;
  }
  .sm\:mmt-5 {
    margin-top: 1.5rem !important;
  }
  .sm\:mmt-6 {
    margin-top: 3.5rem !important;
  }
  .sm\:float-center,
  .sm\:text-center {
    float: none !important;
    text-align: center !important;
    justify-content: center !important;
  }
}

/* End header style */
