/* Begin header style */

.button {
  $buttonSelf: &;

  z-index: 2;
  border: none;
  outline: none;
  background: none;
  overflow: hidden;
  cursor: pointer;
  @include padding(10 25);
  display: inline-block;
  border: 2px solid $transparent;
  @include font-size(14);
  line-height: 1.5;
  @include transition(all 0.3s ease);
  text-align: center;
  height: 40px;
  @include inline-flex;
  @include align-items(center);
  @include justify-content(center);
  font-family: $secondaryFont;

  &-block {
    @include flexbox;
    width: $viewportSize;
  }

  &-inline {
    @include inline-flex;
  }

  &-rounded {
    @include border-radius(50px);
  }

  &-radius {
    @include border-radius(4px);
  }

  &-sm {
    height: auto;
    @include font-size(15);
    @include padding(10 25);
  }

  &-md {
    height: auto;
    @include font-size(15);
    @include padding(10 30);
  }

  &-lg {
    height: auto;
    @include font-size(22);
    @include padding(14 35);
  }

  &::before {
    top: 0px;
    width: 0%;
    left: -125%;
    z-index: -1;
    content: "";
    height: 100%;
    position: absolute;
    @include transform(skew(50deg));
    transition-duration: 0.6s;
    background-color: rgba($white, 0.2);
  }

  &:hover {
    &::before {
      width: 280%;
    }
  }

  &-blue {
    color: $white;
    border-color: $blue;
    background-color: $blue;

    &:hover {
      color: $white;
      border-color: lighten($blue, 15%);
      background-color: lighten($blue, 15%);
    }

    &-bordered {
      color: $blue;
      border-color: $black_sh3;
      background-color: $white;

      &:hover {
        color: $white;
        border-color: $blue_sh1;
        background-color: $blue_sh1;
      }
    }
  }

  &-info {
    color: $primary;
    font-weight: $weightMedium;
    border: 0 none !important;
    background-color: $blue_sh4;

    &:hover {
      color: $blue_sh2;
      border-color: darken($blue_sh4, 5%);
      background-color: darken($blue_sh4, 5%);
    }

    &-bordered {
      color: $info;
      border-color: $info;
      background-color: $white;

      &:hover {
        color: $white;
        border-color: darken($info, 10%);
        background-color: darken($info, 10%);
      }
    }
  }

  &-red {
    color: $white;
    border-color: $error;
    background-color: $error;

    &:hover {
      color: $white;
      border-color: $blackred;
      background-color: $blackred;
    }

    &-bordered {
      color: $error;
      border-color: $error;
      background-color: $white;

      &:hover {
        color: $white;
        border-color: $blackred;
        background-color: $blackred;
      }
    }
  }

  &-white {
    &-bordered {
      color: $white;
      border-color: $white;
      background-color: transparent;

      &:hover {
        color: $black;
        border-color: $white;
        background-color: $white;
      }
    }
  }

  &-grey {
    color: $white;
    border-color: $greyish;
    background-color: $greyish;

    &:hover {
      color: $white;
      border-color: darken($greyish, 7.5%);
      background-color: darken($greyish, 7.5%);
    }

    &-bordered {
      color: $greyish;
      border-color: $greyish;
      background-color: $white;

      &:hover {
        color: $white;
        border-color: darken($greyish, 7.5%);
        background-color: darken($greyish, 7.5%);
      }
    }
  }

  &-lightgrey {
    color: $primary;
    border-color: $blue_sh4;
    background-color: $blue_sh4;

    &:hover {
      color: $primary;
      border-color: darken($blue_sh4, 8.5%);
      background-color: darken($blue_sh4, 8.5%);

      &::before {
        width: 180%;
      }
    }
  }

  &-boderwidth1 {
    border-width: 1px;
  }

  + .c-button {
    margin-left: 10px;
  }

  &-disabled {
    @include opacity(0.5);
    pointer-events: none;
  }

  &-text {
    height: auto;
    border: 0px none;
    text-align: left;
    min-width: inherit;
    @include padding(0);
    text-transform: capitalize;
    background-color: transparent;
    @include transition(all 0.3s ease);
  }

  &-link-muted {
    color: $greyish;
    @include font-size(14);
    line-height: 1.4;
    @include transition(all 0.3s ease);

    svg,
    .img_svg {
      color: $greyish;
      @include transition(all 0.3s ease);
    }

    &:hover,
    &:focus,
    &:active {
      color: $blue_sh1;
      @include opacity(1);

      svg,
      .img_svg {
        color: $blue_sh1;
      }
    }
  }

  &:disabled {
    @include opacity(0.5);
    pointer-events: none;
    border-color: $greyish;
    background-color: $greyish;
  }

  &-primary-gradient {
    @include padding(2);
    display: inline-block;
    border-radius: 10000px;
    background-image: -webkit-gradient(
      linear,
      left top,
      right top,
      from($gradient-1),
      color-stop(49%, $gradient-2),
      to($gradient-3)
    );
    background-image: linear-gradient(
      90deg,
      $gradient-1,
      $gradient-2 49%,
      $gradient-3
    );
    @include box-shadow(0 3px 7px 0 rgba(19, 17, 26, 0.14));
    @include transform(scale3d(1, 1, 1.01));
    -webkit-transition: -webkit-transform 300ms ease;
    transition: -webkit-transform 300ms ease;
    transition: transform 300ms ease;
    transition: transform 300ms ease, -webkit-transform 300ms ease;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    animation: animate 10s linear infinite;
    @extend .easing;

    &:hover {
      @include transform(none);
      @include transform(scale3d(1.04, 1.04, 1.01));
    }
  }
}
@keyframes animate {
  to {
    background-position: 400%;
  }
}

.button-transparent {
  color: $black_sh3;
  cursor: pointer;
  border: 0px none;
  @include opacity(1);
  @include font-size(15);
  background-color: transparent;
  @include flexbox();
  text-align: center;
  @include align-items(center);

  .icon {
    color: $black_sh3;
    @include size(18px);
    @include opacity(1);
    text-align: center;
    @include font-size(14);
    line-height: 25px;
    @include margin-right(5);
  }

  &:last-child {
    .icon {
      @include margin-left(5);
      @include margin-right(0);
    }
  }
  &:first-child {
    .icon {
      @include margin-left(0);
      @include margin-right(5);
    }
  }

  &:hover,
  &:focus,
  &:active {
    color: $red;
    @include opacity(1);

    .icon {
      color: $red;
    }
  }

  &.selected {
    .icon {
      color: $red;
      @include opacity(1);
    }
  }
}

/* End header style */
