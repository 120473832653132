/* Begin custom fonts face files
   ======================== */
@font-face {
  font-family: "CCMaladroittRegular";
  src: url("#{$custom-font-path}/custom/CCMaladroittRegular.eot");
  src: url("#{$custom-font-path}/custom/CCMaladroittRegular.eot")
      format("embedded-opentype"),
    url("#{$custom-font-path}/custom/CCMaladroittRegular.woff2") format("woff2"),
    url("#{$custom-font-path}/custom/CCMaladroittRegular.woff") format("woff"),
    url("#{$custom-font-path}/custom/CCMaladroittRegular.ttf")
      format("truetype"),
    url("#{$custom-font-path}/custom/CCMaladroittRegular.svg#CCMaladroittRegular")
      format("svg");
  font-style: normal;
  font-weight: normal;
  font-display: auto;
}

@font-face {
  font-family: "CCMaladroittBold";
  src: url("#{$custom-font-path}/custom/CCMaladroittBold.eot");
  src: url("#{$custom-font-path}/custom/CCMaladroittBold.eot")
      format("embedded-opentype"),
    url("#{$custom-font-path}/custom/CCMaladroittBold.woff2") format("woff2"),
    url("#{$custom-font-path}/custom/CCMaladroittBold.woff") format("woff"),
    url("#{$custom-font-path}/custom/CCMaladroittBold.ttf") format("truetype"),
    url("#{$custom-font-path}/custom/CCMaladroittBold.svg#CCMaladroittBold")
      format("svg");
  font-style: normal;
  font-weight: normal;
  font-display: auto;
}

/* End custom fonts face files
   ======================== */
