/* Begin footer style */

.footer-wrap {
  $footerSelf: &;

  font-size: 17px;
  color: $greyish;
  @include padding(40 0);
  background-color: $footer;

  @include responsive(sm) {
    @include padding(45 0);

    @include respond-to(md) {
      @include padding(60 0);
    }
  }

  h4 {
    font-size: 24px;
    color: $hoverColor;
    margin-bottom: 25px;
  }

  .footer-top {
    &::before {
      left: 0;
      right: 0;
      top: 15px;
      width: 100%;
      height: 1px;
      content: "";
      position: absolute;
      background-color: $blue_sh4;

      @media (max-width: 767px) {
        top: 13px;
      }
    }
  }

  .footer-about {
    width: 100%;
    @include padding-right(40);

    p {
      font-size: 17px;
      line-height: 1.4;
    }
  }

  .footer-logo {
    width: 100%;
    display: block;
    max-width: 175px;
    @include margin-bottom(25);

    @media (max-width: 767px) {
      max-width: 140px;
    }
  }

  .footer-contact,
  .footer-links {
    margin: 0;
    @include padding-left(40);

    @media (max-width: 767px) {
      @include margin-top(35);
      @include padding-left(0);
    }
  }

  .footer-contact,
  .footer-links {
    .nav-item,
    &-lists,
    &-item {
      margin: 0;
      padding: 0;
      font-size: 17px;
      list-style: none;
      @include margin-top(10);

      &:first-child {
        @include margin-top(0);
      }
    }
    a {
      padding: 1px 0;
      font-size: 17px;
      color: $greyish;
      position: relative;
      display: inline-flex;
      @extend .easing;
    }
    &-lists {
      @include flex-direction(column, vertical);
    }
  }

  .footer-contact {
    .nav-item {
      max-width: 80%;
      position: relative;
      @include padding-left(30);
    }

    a {
      &:hover,
      &:focus,
      &:active {
        color: lighten($hoverColor, 20%);
      }
    }
  }

  .footer-links {
    a {
      &::before {
        left: 0;
        top: 2px;
        color: $greyish;
        content: "\e847";
        position: absolute;
        font-family: $feather;
        @include opacity(0);
        visibility: hidden;
        @extend .easing;
      }

      &:hover,
      &:focus,
      &:active {
        color: lighten($hoverColor, 20%);
        padding-left: 20px;

        &::before {
          left: 0;
          @include opacity(1);
          visibility: visible;
          color: lighten($hoverColor, 20%);
        }
      }
    }
  }
}

/* End footer style */
