/* Layout --> Hero Banner
----------------------------- */
.image-cover {
  width: 100%;
  @include object-fit(cover!important);
  background-repeat: no-repeat !important;
  @include background-size(cover!important);
  background-position: center center !important;
}

.text-gradient {
  background: linear-gradient(
    135deg,
    $gradient-1 0%,
    $gradient-2 25%,
    $gradient-3 50%,
    $gradient-2 75%,
    $gradient-1 100%
  );
  background-size: 400%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: animate 10s linear infinite;
}
@keyframes animate {
  to {
    background-position: 400%;
  }
}

.hero-overlay {
  top: 0;
  left: 0;
  z-index: 1;
  content: "";
  font-size: 16px;
  @include flexbox;
  position: absolute;
  @include size(100%);
  @include align-items(center);
  @include justify-content(center);
  @include linear-gradient(to bottom, rgba($black, 0), rgba($black, 0));
}

.hero-slider {
  font-size: 0;
  position: relative;
}

.hero-slider {
  position: relative;
  .image-cover {
    @include object-fit(cover!important);
  }
  .slides,
  .slides li {
    margin: 0px;
    padding: 0px;
  }
}

.hero-slider {
  width: 100%;

  &-overlay {
    @extend .hero-overlay;

    @media (max-width: 991px) {
      @include align-items(flex-end);
      @include padding-bottom(100);
    }
  }

  &-wrapper {
    width: 100%;
    max-width: 100%;
    text-align: left;
    @include padding-top(25);

    @media (max-width: 991px) {
      margin-top: 0;
      @include margin-bottom(25);
    }

    .heading {
      color: $primary;
      text-align: left;
      font-size: clamp(2.7994rem, 2.4462rem + 1.7658vw, 3.8147rem);
      line-height: 1.25;
      @include margin-bottom(10);
      font-family: $secondaryFont;
      font-weight: $weightBold !important;

      span {
        width: 100%;
        text-align: left;
        display: inline-block;
      }

      @media (max-width: 576.98px) {
        font-size: clamp(2.125rem, 2rem + 1.25vw, 2.25rem);
      }
      @media screen and (min-width: 577px) and (max-width: 767.98px) {
        font-size: clamp(2.25rem, 2.125rem + 1.375vw, 2.375rem);
      }
      @media screen and (min-width: 768px) and (max-width: 1199.98px) {
        font-size: clamp(2.3328rem, 2.0827rem + 1.2504vw, 3.0518rem);
      }
    }

    .description {
      color: $white;
      text-align: left;
      font-size: clamp(1.5rem, 1.25rem + 0.25vw, 1.5rem);
      line-height: 1.5;
      @include margin-bottom(25);
      font-family: $primaryFont;

      @media (max-width: 576.98px) {
        font-size: clamp(1.25rem, 1.125rem + 0.25vw, 1.25rem);
      }
      @media screen and (min-width: 577px) and (max-width: 767.98px) {
        font-size: clamp(1.25rem, 1.125rem + 0.25vw, 1.25rem);
      }
    }

    @media (max-width: 991px) {
      text-align: left;
    }
  }
}

.cta-button {
  @extend .w-full;
  text-align: left;
  @extend .u-flexed;
  @include margin-top(15);

  .button {
    border: 0 none;
    @include box-shadow(0 6px 8px -4px rgba($black, 0.35));

    &-primary {
      min-height: 60px;
      color: $white;
      font-size: 1.5rem;
      line-height: 30px;
      border: 3px solid darken($primary, 10%);
      @include padding(15 35);
      background-color: $primary;
      font-weight: $weightBold;
      border-radius: 30vw;
      text-shadow: 0 2px 0 rgba($black, 0.25);
      @include transition(all 0.3s ease);

      @media (max-width: 767px) {
        min-height: 50px;
        font-size: 1.25rem;
        @include padding(10 24);
        line-height: 30px;
        white-space: nowrap;
      }
    }

    &:hover,
    &:focus,
    &:active {
      transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
    }
  }
}
